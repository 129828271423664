<template>
    <el-dialog
        :title="title"
        :visible.sync="isShow"
        :width="dialogWidth"
        @open="handlerOpen"
        @closed="handlerReset"
        :close-on-click-modal="false"
    >
        <RoleInfo
            v-if="isAdd || isEdit"
            ref="role-info"
            :type="type"
            :data="data"
            :key="key"
        />
        <RoleDetail v-else ref="role-detail" :data="data" :key="key" />
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handlerClose">关闭</el-button>
            <el-button size="mini" v-if="isAdd || isEdit" @click="handlerReset"
                >重置</el-button
            >
            <el-button
                type="primary"
                size="mini"
                v-if="isAdd || isEdit"
                @click="handlerSave"
                >保存</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import RoleInfo from "./roleInfo.vue";
import RoleDetail from "./roleDetail.vue";
export default {
    name: "RoleDialog",
    components: { RoleInfo, RoleDetail },
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: String
        },
        visible: {
            type: Boolean,
            required: true
        },
        data: {
            type: Object
        }
    },
    computed: {
        isShow: {
            get: function() {
                return this.visible;
            },
            set: function(v) {
                this.$emit("update:visible", v);
            }
        },
        isAdd() {
            return this.type === "add";
        },
        isEdit() {
            return this.type === "update";
        },
        dialogWidth() {
            return this.isAdd || this.isEdit ? "400px" : "800px";
        }
    },
    data() {
        return {
            key: 0
        };
    },
    methods: {
        handlerReset() {
            this.$refs["role-info"].handlerReset();
        },
        handlerOpen() {
            this.key++;
        },
        handlerClose() {
            this.isShow = false;
        },
        handlerSave() {
            this.$refs["role-info"].handlerSave().then(() => {
                this.handlerClose();
                this.$emit("reload");
            });
        }
    }
};
</script>
<style lang="scss" scoped></style>
