export const genMenuChildren = oMenu => {
    let menus = Object.assign([], oMenu);
    menus.sort(function(a, b) {
        return a.sort - b.sort;
    });
    menus.forEach(item => {
        if (item.parent_id != -1) {
            for (let menu of menus) {
                if (menu.id === item.parent_id) {
                    if (!Array.isArray(menu.children)) {
                        menu.children = [];
                    }
                    menu.children.push(item);
                    break;
                }
            }
        }
    });
    return menus;
};

export const findMenuPathName = (menus, id) => {
    let menuPath = [];
    for (let menu of menus) {
        if (menu.id == id) {
            menuPath.push(menu.menu_name);
            return menuPath;
        } else {
            if (Array.isArray(menu.children)) {
                let names = findMenuPathName(menu.children, id);
                if (Array.isArray(names) && names.length > 0) {
                    menuPath.push(menu.menu_name);
                    menuPath = menuPath.concat(names);
                    return menuPath;
                }
            }
        }
    }
};
