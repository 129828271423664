<template>
    <div>
        <el-form
            ref="roleInfo"
            :model="form"
            label-width="80px"
            size="mini"
            :rules="rules"
        >
            <el-form-item label="角色" prop="role">
                <el-input v-if="isAdd" v-model="form.role"></el-input>
                <span v-else>{{ form.role || "-" }}</span>
            </el-form-item>
            <el-form-item label="角色名称" prop="role_name">
                <el-input
                    v-if="isAdd || isEdit"
                    v-model="form.role_name"
                ></el-input>
                <span v-else>{{ form.role_name || "-" }}</span>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { addRoleApi, updateRoleApi } from "@/api/system/role.js";

export default {
    name: "RoleInfo",
    props: {
        type: {
            type: String
        },
        data: {
            type: Object
        }
    },
    computed: {
        isAdd() {
            return this.type === "add";
        },
        isEdit() {
            return this.type === "update";
        },
        rules() {
            let { role, role_name } = this.allRules;
            let rule = {};
            if (this.isAdd) {
                rule = Object.assign(rule, {
                    role,
                    role_name
                });
            } else if (this.isEdit) {
                rule = Object.assign(rule, {
                    role_name
                });
            } else {
                rule = {};
            }
            return rule;
        },
        reqParams() {
            let { id, role, role_name } = this.form;
            return {
                id,
                role,
                role_name
            };
        }
    },
    data() {
        return {
            form: {},
            allRules: {
                role: [
                    {
                        required: true,
                        message: "请输入角色标识",
                        trigger: "blur"
                    },
                    {
                        min: 3,
                        max: 50,
                        message: "长度在 3 到 50 个字符",
                        trigger: "blur"
                    }
                ],
                role_name: [
                    {
                        required: true,
                        message: "请输入角色名称",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    methods: {
        setData() {
            if (this.isAdd) {
                this.$set(this, "form", {
                    role: "",
                    role_name: ""
                });
            } else {
                this.$set(this, "form", Object.assign({}, this.data));
            }
        },
        handlerReset() {
            this.$refs["roleInfo"].resetFields();
        },
        async handlerSave() {
            return new Promise((resolve, reject) => {
                this.$refs["roleInfo"].validate(valid => {
                    if (valid) {
                        let action = undefined;
                        if (this.type == "add") {
                            action = addRoleApi;
                        } else if (this.type == "update") {
                            action = updateRoleApi;
                        } else {
                            reject();
                            return false;
                        }
                        action(this.reqParams)
                            .then(res => {
                                if (res.data.code == 0) {
                                    this.$notify({
                                        title: "成功",
                                        message: "保存成功",
                                        type: "success"
                                    });
                                    resolve();
                                } else {
                                    this.$notify.error({
                                        title: "失败",
                                        message: res.data.msg || "保存失败"
                                    });
                                    reject();
                                }
                            })
                            .catch(error => {
                                this.$notify.error({
                                    title: "异常",
                                    message: error
                                });
                                reject();
                            });
                    } else {
                        reject();
                        return false;
                    }
                });
            });
        }
    },
    created() {
        this.setData();
    }
};
</script>
<style lang="scss" scoped></style>
