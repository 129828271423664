import request from "@/router/axios";

export const getRolePageApi = params => {
    return request({
        url: "/api/system/role/page",
        method: "get",
        params
    });
};

export const getAllRoleApi = params => {
    return request({
        url: "/api/system/role/list",
        method: "get",
        params
    });
};

export const addRoleApi = data => {
    return request({
        url: "/api/system/role/insert",
        method: "post",
        data
    });
};

export const updateRoleApi = data => {
    return request({
        url: "/api/system/role/update",
        method: "put",
        data
    });
};

export const deleteRoleApi = data => {
    return request({
        url: "/api/system/role/delete",
        method: "delete",
        data
    });
};

export const getMenusApi = params => {
    return request({
        url: "/api/system/role/menus",
        method: "get",
        params
    });
};

export const bindMenusApi = data => {
    return request({
        url: "/api/system/role/bindMenus",
        method: "post",
        data
    });
};
