<template>
    <el-descriptions
        class="role-detail"
        labelClassName="role-detail-label"
        :column="2"
        border
    >
        <el-descriptions-item label="角色标识">{{
            data.role
        }}</el-descriptions-item>
        <el-descriptions-item label="角色名称">{{
            data.role_name
        }}</el-descriptions-item>
        <el-descriptions-item label="菜单">
            <el-tree
                class="menu-tree"
                :data="relMenus"
                :props="defaultProps"
                default-expand-all
            >
            </el-tree>
        </el-descriptions-item>
    </el-descriptions>
</template>
<script>
import { getMenusApi } from "@/api/system/role";
import { genMenuChildren } from "@/utils/menu";

export default {
    name: "RoleDetail",
    props: {
        data: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    computed: {
        relMenus() {
            let menus = genMenuChildren(this.menus);
            return menus.filter(item => {
                return item.parent_id == -1 && item.type == 0;
            });
        }
    },
    data() {
        return {
            menus: [],
            defaultProps: {
                label: "menu_name",
                children: "children"
            }
        };
    },
    methods: {
        async getRoleMenu() {
            return new Promise((resolve, reject) => {
                getMenusApi(this.data)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "menus", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        }
    },
    created() {
        this.getRoleMenu();
    }
};
</script>
<style lang="scss" scoped>
.role-detail {
    /deep/ .role-detail-label {
        width: 80px;
    }
    .menu-tree {
        max-height: 250px;
        overflow: auto;
    }
}
</style>
